import React from 'react'
import Layout from '../components/layout'
import OfficeStyles from '../components/openOffice.module.css'
import { graphql } from'gatsby'
import PortableText from '@sanity/block-content-to-react'
import urlBuilder from "@sanity/image-url";
import { useEffect } from 'react'

export const officeData = graphql `
query MyOfficeQuery {
    allSanityOpenOffice(sort: {fields: listDate, order: DESC}) {
      edges {
        node {
          _id
          _createdAt
          introFr
          introEng
          titleEng
          subTitleEng
          titleFr
          subTitleFr
          name
          dates
          _rawBodyFr
          _rawBodyEng
          articlePDF {
            asset {
              url
              originalFilename
            }
          }
          articlePDFName
        }
      }
    }
  }
`;

const urlFor = source =>
  urlBuilder({ projectId: "4iqgwgc6", dataset: "production" }).image(source);

const serializer = {
    types: {
      image: props => (
        <figure>
          <img
            src={urlFor(props.node.asset)
              //.width(600)
              .url()}
            alt={props.node.alt}
          />
  
        </figure>
      ),
      podcast: props => {
    
      const theUrl = props.node.url;
      if(!theUrl){
          return <div>There is no URL.</div>
      }
      const urlAddress = theUrl.replace(/[/|[\]//]/g, '%2F');
      const url = urlAddress.split(".com").pop()
      const srcURL = `https://www.mixcloud.com/widget/iframe/?light=1&feed=${url}`; 
    
      if(!srcURL) {
        return <div>Missing URL</div>
      }

      return(
        <iframe width="100%" 
          title="podcast"
          height="auto" 
          src={srcURL} 
          frameBorder="0" >
        </iframe> 
        )
      },
      soundcloud: props => {
    
        const theUrl = props.node.url;
        if(!theUrl){
            return <div>There is no URL.</div>
        }
        const urlAddress = theUrl.replace(/[/|[\]//]/g, '%2F');
        const url = urlAddress.split(".com").pop()
        const srcURL = `https://w.soundcloud.com/player/?url=${url}`; 
      
        if(!srcURL) {
          return <div>Missing URL</div>
        }
  
        return(
          <iframe width="100%" 
            title="soundcloud"
            height="auto" 
            src={srcURL} 
            frameBorder="0" >
          </iframe> 
          )
        }
    }
  };
  

const OpenOffice = ({ data }) =>{
  const len = data.allSanityOpenOffice.edges.length-1
  //console.log(len)
    const officeData = data.allSanityOpenOffice.edges;
    const officeSubFr = data.allSanityOpenOffice.edges[len].node.introFr;
    const officeSubEng = data.allSanityOpenOffice.edges[len].node.introEng;

  useEffect(() => {      
      const accordion = document.getElementsByClassName("collapsible");
        for (var i = 0; i < accordion.length; i++) {
              accordion[i].addEventListener("click", function(event) {           
              accordionClick(event)
    });
}
let accordionClick = (event) => {
  var targetClicked = event.target.parentElement;
  //const cross = targetClicked.getElementsByClassName("collapsible-cross");

  targetClicked.classList.toggle("active");
  var content = targetClicked.nextElementSibling;
  if(content.style.maxHeight){
    content.style.maxHeight = null;
  }
  else{
    var allContent = document.getElementsByClassName("collapsible-content");    
     for (var i = 0; i < allContent.length; i++){    
          //console.log("current description: " + allContent[i]);
          if (allContent[i].style.maxHeight){
              allContent[i].style.maxHeight = null;
          }
      }
      content.style.maxHeight = content.scrollHeight + "px";
    }
  }
  
 });
    return(
      <div className="openOffice-background">
      <div className="openOffice-page">
        <Layout>           
            <div className= "headerSpace"></div>
            <div className={OfficeStyles.subTexts}>
                          <p>
                            {officeSubFr} 
                          </p>
                          <p className={OfficeStyles.englishSub}>
                              {officeSubEng} 
                          </p>
                    </div> 
            {
                officeData.map(({ node }) => (
                  
                  <div key={node._id}>
                   
                    <div className="collapsible">
                        <li>{node.dates}</li>
                        <li className="collapsible-titles">{node.titleFr}</li>
                        <li className="collapsible-name">{node.name}</li>
                        <li className="collapsible-titles-en">
                          {node.titleEng}</li>
                    </div>
                      
                      <div  key = {node.titleEng}className="collapsible-content">
                        <div className={OfficeStyles.frenchText}>
                          <div className= {OfficeStyles.articleTitle}>
                            <h3>{node.titleFr}</h3>
                            <p>{node.subTitleFr}</p>
                            <h3 className={OfficeStyles.titleEn}>{node.titleEng}</h3>
                            <p className={OfficeStyles.titleEn}>{node.subTitleEng}</p>
                          </div>

                            <div className={OfficeStyles.startText}>
                              <PortableText
                                blocks = { node._rawBodyFr } 
                                serializers = { serializer }
                              />
                            </div>
                        </div>

                        <div className={OfficeStyles.englishText}>                 
                            <div className="content">
                              <PortableText
                                blocks = { node._rawBodyEng } 
                                serializers = { serializer }
                                /*imageOptions={{w: 320, h: 240, fit: 'max'}}
                                projectId="4iqgwgc6"
                                dataset="production"*/
                              />
                            </div>
                        </div>
                            {node.articlePDF ? (
                            <p>
                               <a href={node.articlePDF.asset.url}> {node.articlePDFName}</a>
                            </p>
                            ):(
                              <div></div>
                            )
                            }

                      </div>
                  </div>
                ))
            }
  
  
        </Layout>
      </div>
      </div>
    )
}

export default OpenOffice